/* NoticiaCompleta.css */
.container {
    margin-top: 20px;
    background-color: rgb(212, 223, 223);
}

h2 {
    margin-bottom: 20px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

p {
    margin-bottom: 20px;
}
